import "./Operation.scss"

export const Operation = (): JSX.Element => {
  const items = [
    {
      title: "Staking Pool Filling Progress",
      list: [
        {
          title: '6,820PiB',
          text: 'Profit Ratio Per Day',
        },
      ],
    },
    {
      title: "Staking Pool Data Information",
      list: [
        {
          title: '1,179,976PiB',
          text: 'Profit per day',
        },
        {
          title: '8,220.17PiB',
          text: 'Profit per week',
        },
        {
          title: '22.8819PiB',
          text: 'Profit per month',
        },
        {
          title: '0.0435PiB',
          text: 'Profit per year',
        },
      ],
    },
  ]

  return (
    <div className="section operation">
      <div className="section-wrapper operation-wrapper">
        <h2>Staking Pool <span>Operation Data</span></h2>
        <div className="operation__items">
          {items.map((item, index) => (
            <div key={index} className="operation__item gradient">
              <h3 className="gradient">
                <span>{item.title}</span>
              </h3>
              <div className="operation-list">
                {item.list.map((el, index) => (
                  <div key={index} className="operation-list__item">
                    {index !== 0 && <div className="operation__outline" />}
                    <h4>{el.title}</h4>
                    <p>{el.text}</p>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
