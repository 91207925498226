import {
  Data,
  Features,
  Footer,
  Future,
  Header,
  List,
  Partners,
  TextImage,
  Operation,
} from 'components';

import "./Home.scss"
import {Pool} from "../../components/Pool";

export const Stake = (): JSX.Element => {
  const pools = [
    {
      days: '14',
      percent: '1.25%',
      limits: '100-800'
    },
    {
      days: '30',
      percent: '1.73%',
      limits: '500-1500'
    },
    {
      days: '60',
      percent: '1.95%',
      limits: '800-5000'
    },
    {
      days: '90',
      percent: '2.22%',
      limits: '5000-10.000'
    },
    {
      days: '160',
      percent: '3.5%',
      limits: 'from 10.000',
      comingSoon: true
    },
  ]
  return (
    <main>
      <Header/>
      <div className='main-content-wrapper'>
        <div className='main-content'>
          {pools.map((i, index) => (
            <Pool key={index} days={i.days} percents={i.percent} comingSoon={i.comingSoon} limits={i.limits}/>
          ))}
        </div>
      </div>
      <Footer />
    </main>
  );
}
