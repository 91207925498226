import { Footer, Form, Header } from "components"

import email  from "../../assets/icons/email.svg"
import "./ContactUs.scss"
import bg1 from '../../assets/images/bg1.png';

export const ContactUs = (): JSX.Element => {
  const data = [
    {
      title: "Email Address",
      icon: email,
      value: "cs@cnc.finance",
      link: "mailto:cs@cnc.finance",
    },
    // {
    //   title: 'Phone Number',
    //   icon: <Phone />,
    //   value: '93526 356272',
    //   link: "tel:93526 356272"
    // },
    // {
    //   title: "Our Address",
    //   icon: <Map />,
    //   value: "Floor 17 Cricket Square George Town Willow House Grand Cayman, KY1,90910, Cayman Islands",
    //   link: "",
    // },
  ]

  return (
    <div
      className="contact-us"
      style={{ backgroundImage: `url("${bg1}")` }}
    >
      <Header />
      <div className="contact-us-content section">
        <div className="section-wrapper">
          <h1 className="contact-us-content-title">
            Get <span>In Touch</span> With Us
          </h1>
          <p className="contact-us-content-desc">
            We are happy to answer any questions you may have, so don’t hesitate to contact us and we will get back to you
            as soon as possible.
          </p>
          <div className="contact-us-content-wrapper">
            <div className="contact-us-content-wrapper-social gradient">
              {data.map((item, index) => (
                <a key={index} className="contact-us-content-wrapper-social-item" href={item.link || ""}>
                  <div className="contact-us-content-wrapper-social-item-image-wrapper">
                    <img src={item.icon} alt="email" />
                  </div>
                  <div className="contact-us-content-wrapper-social-item-text">
                    <div className="contact-us-content-wrapper-social-item-text-title">{item.title}</div>
                    <div className="contact-us-content-wrapper-social-item-text-value">{item.value}</div>
                  </div>
                </a>
              ))}
            </div>
            <Form />
          </div>
        </div>
        {/*<div className="future-shadow" />*/}
      </div>
      <Footer />
    </div>
  )
}
