import { Collapse, Footer, Header } from "components"

import "./FAQ.scss"
import bg2 from '../../assets/images/bg2.png';

export const FAQ = (): JSX.Element => {
  return (
    <div
      className="faq"
      style={{ backgroundImage: `url("${bg2}")` }}
    >
      <Header />
      <div className="faq-content">
        <h1 className="faq-content-title">
          Frequently <span>Asked Questions</span>
        </h1>
        <p className="faq-content-desc">Have questions? We’re here to help.</p>
        <Collapse
          title="What interest rates do you pay?"
          desc="You receive a flat 7% interest on stablecoins, 20% on Ethereum and 30% on File Coins and it is all yours to keep. Funds can be added and removed in cryptocurrency or fiat currency daily."
        />
        <Collapse
          title="Do you charge any fees?"
          desc="When you withdraw funds from the platform there is no fee for fiat withdrawals. There is a $20 for crypto withdrawals, charged in the asset you are withdrawing. What payment methods do you accept? We accept payment by card, bank transfer, or wallet-to-wallet crypto payments (currently accepting BTC,ETH,BNB,USDT, FIL,UNI)"
        />
        <Collapse
          title="How do you pay such high rates of return?"
          desc="CNC Finance generates returns by lending out our customers digital assets to institutional and retail borrowers as well as participating and supporting decentralised exchanges. We have a rigorous risk management process and insure deposits that we lend to decentralised exchanges. Where we lend money to retail investors and institutions, we ensure that it is 100%+ collateralised.  This strategy means funds are easily accessible and we pay out returns to customers daily. Returns generated by cryptocurrency deposits remain high as market participants are willing to pay high levels of interest to borrow assets they struggle to access via traditional needs. As the crypto market develops yields will decrease as capital becomes more accessible, however, we feel there will also be opportunities that emerge to generate yield to compensate."
        />
        <Collapse
          title="Are you secure?"
          desc="Cryptocurrencies are inherently high-risk products as blockchain technology is new. The main risks faced by companies within the crypto space are: Hacking – crypto wallets held by CNC Finance are penetrated by third parties. CNC Finance uses leading wallet infrastructure provider Fireblocks to ensure security of assets. We also have a $30 million policy in the event assets are stolen. Coin risk – if the underlying coins are disrupted by bad actors they could lose a large portion of their value. For example, if someone were to launch a 51% attack on Ethereum, they could issue large amounts of new Ethereum thus devaluing it. We see this risk as relatively low as we only provide access to the largest most liquid cryptocurrencies on our platform."
        />
        <Collapse
          title="What is the minimum deposit and withdrawal?"
          desc="The minimum deposit amount is $100. There isn’t a withdrawal minimum as long as you can cover the fee."
        />
        <Collapse
          title="Do I still own my coins when I send them to CNC Finance?"
          desc="CNC Finance acts as a cryptocurrency custodian. By doing so, we take full control of customer assets when they are deposited. These deposits are protected by our wallet insurance provided by Fireblocks."
        />
        <Collapse
          title="How long does it take to deposit or remove funds?"
          desc="For cryptocurrency we will usually notify you of receipt of funds within 20 minutes of you making a transfer. With fiat currency, this will depend on your bank and method of payment. Some transfers can take as little as an hour to be confirmed others, such as those sent internationally, can take up to 5 days to clear depending on where your bank is base"
        />
        <Collapse
          title="Why do I need to provide identification?"
          desc="We take all regulatory and anti-money laundering requirements extremely seriously and adhere to all requirements set by regulators. We have a responsibility to ‘know our customer’ and ensure we know the source of any funds transferred to an account. Please be patient with our questions and document requests as these processes only strengthen the credibility and security of the cryptocurrency ecosystem."
        />
        <Collapse
          title="Who are CNC Finance?"
          desc="CNC Finance was created by an experienced team of investment, payment and technology experts. CNC Finance was created out of a frustration with the difficulty that many people face to generate returns from their cryptocurrency. The crypto market was initially created to make financial services available to everyone however, it has quickly become complex, full of jargon and impenetrable to everyday investors. CNC Finance is here to change that. We have created an easy-to-use platform making it simple to convert fiat into crypto and begin earning high returns on your chosen assets immediately."
        />
        <Collapse
          title="Which cryptocurrencies do you cover?"
          desc="You can deposit or invest in Bitcoin (BTC) and Ethereum (ETH) or multiple coins (BNB,USDT,FIL,UNI ). We will add more in response to demand but will always have a strict requirement that the coin have a consistent track record and proven utility."
        />
        <Collapse
          title="Do you accept corporate customers?"
          desc="Yes, we do. To onboard you we will need to identify the business, directors and all beneficial owners. Please email support@cnc.finance to learn more."
        />
      </div>
      <Footer />
    </div>
  )
}
