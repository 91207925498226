import { hrefs, footerLinks } from "utils"

import img1 from "assets/icons/twitter.svg"
import img2 from "assets/icons/telegram.svg"

import "./Footer.scss"

export const Footer = ({ type }: { type?: string }): JSX.Element => {
  const footerItems = [
    {
      text: "Copyright © 2024 CNC Finance",
      href: "",
    },
  ]

  const downloadLinks = [
    {
      icon: img1,
      href: "https://x.com/CNC_Finance",
    },
    {
      icon: img2,
      href: "https://t.me/CNCFinance",
    },
  ]

  return (
    <div className="section footer">
      <div className="section-wrapper footer-container">
        <div className="footer-right">
          {footerItems.map((item, index) => (
            <div key={index}>
              {item.href ? (
                <a href={item.href}>
                  {item.text}
                </a>
              ) : (
                <span>{item.text}</span>
              )}
            </div>
          ))}
        </div>
        <div className="footer-left">
          {downloadLinks.map((item, index) => (
            <a key={index} href={item.href}>
              <img src={item.icon} alt="download" />
            </a>
          ))}
        </div>
      </div>
    </div>
  )
}
