import "./Partners.scss"

type Props = {
  content: {
    title?: string
    logos: {
      icon: string
    }[]
  }
}

export const Partners = ({ content }: Props): JSX.Element => {
  const { title, logos } = content

  return (
    <div className="section partners">
      <div className="section-wrapper partners-wrapper">
        <h2
          dangerouslySetInnerHTML={{
            __html: title ?? "",
          }}
        />
        <div className={`partners__container`}>
          {logos.map((partner, index) => (
            <div key={index} className="partners__item">
              <img alt="partner" src={partner.icon} />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
