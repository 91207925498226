import { useMedia } from "use-media"
import { useState } from "react"
import cn from "classnames"

import { mixins, routes, useScrollPosition } from "utils"

import { ReactComponent as Burger } from "assets/icons/burger.svg"
import { ReactComponent as Close } from "assets/icons/close.svg"
import logoSrc from "assets/icons/logo.svg"

import "./Header.scss"
import { NavLink } from "react-router-dom"
import {ConnectKitButton} from "connectkit";

export const Header = (): JSX.Element => {
  const scroll = useScrollPosition()
  const [sidebar, setSidebar] = useState(false)
  const isL = useMedia({ maxWidth: mixins.l })

  const toggleSidebar = () => {
    setSidebar(!sidebar)
  }

  const links = [
    {
      title: 'Stake',
      link: '/stake'
    },
    {
      title: 'Contact Us',
      link: '/contact-us'
    },
    {
      title: 'About Us',
      link: '/about-us'
    },
    {
      title: 'FAQ',
      link: '/faq'
    },
  ]

  return (
    <header
      className={cn(
        "header section",
        {
          offset: scroll > 80 || sidebar,
        },
      )}
    >
      <div className="section-wrapper header-container">
        {isL && (
          <div
            className={cn("header-sidebar", {
              active: sidebar,
            })}
          >
            <div className="header-links-mobile">
              {links.map((item, index) => (
                <a className="header-right-link" key={index} href={item.link}>
                  {item.title}
                </a>
              ))}
            </div>
          </div>
        )}
        <div className="header__block_left">
          {isL && (
            <button className="header-mobile-burger" type="button" onClick={toggleSidebar}>
              {sidebar ? (
                <Close className="header-mobile-close" fill={"#FFFFFF"} />
              ) : (
                <Burger fill={"#FFFFFF"} />
              )}
            </button>
          )}
          <a className="header-logo-wrapper" href={routes.index}>
            <img
              src={logoSrc}
              alt="logo"
              width={38}
              height={38}
              className="header-logo"
            />
            {!isL && (
              <span>CNC Finance</span>
            )}
          </a>
          {!isL && (
            <div className="d-flex header-links">
              {links.map((item, index) => (
                <NavLink
                  className="header-links__item"
                  key={index}
                  to={item.link}
                >
                  {item.title}
                </NavLink>
              ))}
            </div>
          )}
        </div>
        <div className="header-buttons">
          <ConnectKitButton />
        </div>
      </div>
    </header>
  )
}
