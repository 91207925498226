import image from "assets/images/App.svg"

import "./Future.scss"

export const Future = (): JSX.Element => {

  return (
    <div className="future section">
      <div className="section-wrapper future-container">
        <div className="future-left">
          <h1>The Future<br /> Of <span>Staking</span> Is Here</h1>
          <p>The Hashtech Minning platform gives you access to unrivalled interest by combining the exciting crypto market, our experienced investment team and a simple and secure platform to bring you reliable returns of up to 12%.</p>
          <a className="future__button" href='/stake'>Get Started</a>
        </div>
        <img src={image} alt="digital assets" className="future-img" />
      </div>
    </div>
  )
}
