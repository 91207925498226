export const routes = {
  index: "/",

  contact: "/contact-us",
  download: "/download",
  privacy: "/privacy-policy",
  terms: "/terms-and-conditions",
  about: "/about-us",
  faq: "/faq",
  referral: "/referral",
  security: "/security",
  exchanges: "/withdraw",
  blog: "/blog",
  blogs: "/blogs",
  corporate: "/corporate",
  markets: "/markets",
  helpCenter: "/help-center",
  products: "/products",
  cookies: "/cookies",
  vulnerability: "/vulnerability",
  mediaCenter: "/media-center",
  affiliate: "/affiliate",
  prime: "/prime",
  stake: "/stake",
}
