import { useState } from "react"

import "./Form.scss"

export const Form = (): JSX.Element => {
  const [name, setName] = useState("")
  const [mail, setMail] = useState("")
  const [phone, setPhone] = useState("")
  const [message, setMessage] = useState("")

  const sendEmail = (data: any) => {
    alert("Mail sent!")
  }

  const sendExample = () => {
    // Can be any data, static and dynamic
    sendEmail({
      email: mail,
      name: name,
      phone: phone,
      message: message,
    })
  }

  return (
    <form onSubmit={sendExample} className="form gradient">
      <div className="form-input-wrapper">
        <input
          required
          id="name"
          value={name}
          onChange={e => {
            setName(e.target.value)
          }}
          placeholder="Enter Name"
          className="form-input"
        />
      </div>
      <div className="form-input-wrapper">
        <input
          required
          id="mail"
          type="email"
          value={mail}
          onChange={e => {
            setMail(e.target.value)
          }}
          placeholder="Email"
          className="form-input"
        />
      </div>
      <div className="form-input-wrapper">
        <input
          required
          id="phone"
          type="tel"
          value={phone}
          onChange={e => {
            setPhone(e.target.value)
          }}
          placeholder="Phone Number"
          className="form-input"
        />
      </div>
      <div className="form-input-wrapper">
        <textarea
          required
          rows={5}
          id="message"
          value={message}
          onChange={e => {
            setMessage(e.target.value)
          }}
          placeholder="Message"
          className="form-textarea"
        />
      </div>
      <button type="submit" className="form-btn">
        Submit
      </button>
    </form>
  )
}
