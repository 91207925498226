import { Footer, Header } from "components"

import imgSrc from "assets/images/download-image.webp"
import playStore from '../../assets/images/download/google-play.svg';

import "./Download.scss"

export const Download = (): JSX.Element => {
  const downloadLinks = [
    {
      icon: playStore,
      href: "/",
    },
  ]

  return (
    <div className="download">
      <Header />
      <div className="section">
        <div className="section-wrapper download-content text-image">
          <div className="future-shadow" />
          <img className="download-content-img" src={imgSrc} alt="phones" />
          {/*<div className="download-content-shadow" />*/}
          <div className="download-content-info">
            <h1 className="download-content-title">
              Start using <span>CNC Finance</span> with WEB APP Version
            </h1>
            <p className="download-content-desc">
              CNC Finance is a borderless Crypto staking platform. WEB APP is the most user-friendly version for user to
              login without any regional issues and inconvenient.
            </p>
            <div className="text-image-links">
              {downloadLinks.map((item, index) => (
                <a className="text-image-links__item" key={index} href={item.href}>
                  <img src={item.icon} alt="download" />
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
