import { Features, Footer, Header } from 'components';

import imgPhone1Src from "assets/images/staking.png"

import img1 from '../../assets/icons/computers/1.svg';
import img2 from '../../assets/icons/computers/2.svg';
import img3 from '../../assets/icons/computers/6.svg';

import bg2 from '../../assets/images/bg2.png';
import img from '../../assets/images/download-image.webp';

import "./About.scss"

export const About = (): JSX.Element => {
  const items = [
    {
      icon: img1,
      title: "Safety",
      text:
        "At CNC Finance, we treat safety as a core value and a process of innovation. We understand the importance of safety and security when it comes to your money.\n" +
        "With a passion for making crypto transactions safe and secure for all, we are always working on delivering practical and innovative solutions for our products.",
    },
    {
      icon: img2,
      title: "Courage",
      text:
        "At CNC Finance, we're guided by courage as we know that it takes unrivalled vision and innovation to shape the future of financial inclusivity for all.\n" +
        "We embrace the realities of changing world situations and are not afraid of making tough decisions to align with our long-term mission.",
    },
    {
      icon: img3,
      title: "User-focused",
      text:
        "We stay connected and listen to our users and community, always asking them for their feedback to gain a deep understanding of their needs in order to create even better things.\n" +
        "We connect data, design and content and introduce new blockchain technologies to provide options for financial freedom and inclusion to our users.",
    },
  ]

  return (
    <div
      className="about"
      style={{ backgroundImage: `url("${bg2}")` }}
    >
      <Header />
      <div className="section about-first">
        {/*<div className="future-shadow" />*/}
        <div className="section-wrapper about-first-content">
            <div className="about-first-content-left">
              <h2 className="about-first-content-left-title">
                Live a <span>Rewarding</span> Life
              </h2>
              <div className="about-first-content-left-option">
                <div className="about-first-content-left-option-dot" />
                Our goal is to enable easy access to various rewarding crypto staking mechanisms for all. We want everyone
                to equally benetit from a changing financial landscape and speed up the global of crypto adoption
                <br/>
                <br/>
              </div>
              <div className="about-first-content-left-option">
                <div className="about-first-content-left-option-dot" />
                CNC Finance Is a self funded start that has already grown into a profltable and salable business over 2
                vears
              </div>
              <a href="" className="about-first-content-left-btn">
                <span className="about-first-content-left-btn-text">Get Started</span>
              </a>
            </div>
            {/*<div className="about-first-content-shadow" />*/}
            <img src={imgPhone1Src} alt="phone" className="about-first-content-img" />
          </div>
      </div>
      <Features
        items={items}
        title={'Our <span>Values</span>'}
      />
      <div className="section text-image">
        <div className="section-wrapper text-image-wrapper">
          <div className="text-image__content">
            <h2>Our <span>Products</span></h2>
            <p>At CNC Finance, we provide our users, both advanced and beginner cryptocurrency holder, with a trusted, protessional and casy-to-uses staking platform. Our products are simple and easy tc understand. We are focused on building unique crypto derivative products for our users to access the blockchain ecosystem easily.</p>
          </div>
          {/*<img className="text-image__image" src={img} alt="background" />*/}
        </div>
      </div>
      <Footer />
    </div>
  )
}
