import "./Features.scss"

type Props = {
  items: {
    title: string;
    text: string;
    icon: string;
  }[]
  title: string;
  text?: string;
}

export const Features = ({items, title, text}: Props): JSX.Element => {

  return (
    <div className="section features">
      <div className="section-wrapper features-wrapper">
        <h2 dangerouslySetInnerHTML={{
          __html: title ?? ""
        }} />
        {text && <p>{text}</p>}
        <div className="features-container">
          {items.map((el, index) => (
            <div key={index} className="features__item gradient">
              <div className="features__item-img-wrapper">
                <img src={el.icon} alt="feature" />
              </div>
              <h3>{el.title}</h3>
              <p>{el.text}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
