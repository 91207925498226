import "./Data.scss"

export const Data = (): JSX.Element => {

  return (
    <div className="data section">
      <div className="section-wrapper node-info">
        <h2 className="data-second-title">
          Node <span>Information</span>
        </h2>
        <h3 className="data-third-title">ETH Node</h3>
        <div className="table-wrapper">
          <table>
            <tr className="data-content-third-header">
              <th>Node Id</th>
              <th>Host</th>
              <th>Port</th>
              <th>Total Difficulty</th>
              <th>Client Type</th>
              <th>Client Version</th>
              <th>OS</th>
              <th>Country</th>
            </tr>
            <tr>
              <td>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href=""
                >
                  292f...f44e
                </a>
              </td>
              <td>209.30.144.92</td>
              <td>30303</td>
              <td>
                <div className="nowrap">47,522,534,134,527,289,553,880</div>
              </td>
              <td>Geth</td>
              <td>go1.18</td>
              <td>darwin-arm64</td>
              <td>United States</td>
            </tr>
            <tr>
              <td>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href=""
                >
                  fa00...8d51
                </a>
              </td>
              <td>54.198.223.59</td>
              <td>30303</td>
              <td>
                <div className="nowrap">47,596,621,056,583,403,157,024</div>
              </td>
              <td>Geth</td>
              <td>go1.18</td>
              <td>linux-amd64</td>
              <td>United States</td>
            </tr>
          </table>
        </div>
        <h3 className="data-third-title">Filecoin Node</h3>
        <div className="table-wrapper">
          <table>
            <tr className="data-content-third-header">
              <th>Address</th>
              <th>Number of messages</th>
              <th>Type</th>
              <th>Created</th>
              <th>Node ID</th>
              <th>Owner</th>
              <th>Worker</th>
            </tr>
            <tr>
              <td>
                <a rel="noreferrer" target="_blank" href="">
                  f01130671
                </a>
              </td>
              <td>695392</td>
              <td>Storage provider account</td>
              <td>2021-07-22 12:02:00</td>
              <td>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href=""
                >
                  12D3...Qg6o
                </a>
              </td>
              <td>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href=""
                >
                  f3q6...sjsq
                </a>
              </td>
              <td>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href=""
                >
                  f3wo...chta
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <a rel="noreferrer" target="_blank" href="">
                  f0145986
                </a>
              </td>
              <td>924464</td>
              <td>Storage provider account</td>
              <td>2021-01-27 05:57:00</td>
              <td>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href=""
                >
                  12D3...ErXf
                </a>
              </td>
              <td>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href=""
                >
                  f1hn...la4q
                </a>
              </td>
              <td>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href=""
                >
                  f3wt...dyya
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <a rel="noreferrer" target="_blank" href="">
                  f01392893
                </a>
              </td>
              <td>322413</td>
              <td>Storage provider account</td>
              <td>2021-10-23 20:08:00</td>
              <td>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href=""
                >
                  12D3...mHYj
                </a>
              </td>
              <td>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href=""
                >
                  f15o...xfjy
                </a>
              </td>
              <td>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href=""
                >
                  f3u4...acyq
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <a rel="noreferrer" target="_blank" href="">
                  f01763182
                </a>
              </td>
              <td>1084227</td>
              <td>Storage provider account</td>
              <td>2022-03-04 10:35:30</td>
              <td>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href=""
                >
                  12D3...mAme
                </a>
              </td>
              <td>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href=""
                >
                  f1rr...cn7i
                </a>
              </td>
              <td>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href=""
                >
                  f3wv...iiha
                </a>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  )
}
