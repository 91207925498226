import {
  Data,
  Features,
  Footer,
  Future,
  Header,
  List,
  Partners,
  TextImage,
  Operation,
} from 'components';

import imgPartner1 from "assets/icons/partners/1.svg"
import imgPartner2 from "assets/icons/partners/2.svg"
import imgPartner3 from "assets/icons/partners/3.svg"
import imgPartner4 from "assets/icons/partners/4.svg"
import imgPartner5 from "assets/icons/partners/5.svg"
import imgPartner6 from "assets/icons/partners/6.svg"
import imgPartner7 from "assets/icons/partners/7.svg"
import imgPartner8 from "assets/icons/partners/8.svg"
import imgPartner9 from "assets/icons/partners/9.svg"

import imgInvestors1 from "assets/icons/investors/1.svg"
import imgInvestors2 from "assets/icons/investors/2.svg"
import imgInvestors3 from "assets/icons/investors/3.svg"
import imgInvestors4 from "assets/icons/investors/4.svg"
import imgInvestors5 from "assets/icons/investors/5.svg"
import imgInvestors6 from "assets/icons/investors/6.svg"
import imgInvestors7 from "assets/icons/investors/7.svg"
import imgInvestors8 from "assets/icons/investors/8.svg"
import imgInvestors9 from "assets/icons/investors/9.svg"

import bg1 from "assets/images/bg1.png"
import bg2 from "assets/images/bg2.png"

import img1 from '../../assets/icons/computers/1.svg';
import img2 from '../../assets/icons/computers/2.svg';
import img3 from '../../assets/icons/computers/3.svg';
import img4 from '../../assets/icons/computers/4.svg';
import img5 from '../../assets/icons/computers/5.svg';
import img6 from '../../assets/icons/computers/6.svg';

import "./Home.scss"

export const Home = (): JSX.Element => {

  const partnersContent = {
    title: `Our <span>Partners</span>`,
    logos: [
      { icon: imgPartner1 },
      { icon: imgPartner2 },
      { icon: imgPartner3 },
      { icon: imgPartner4 },
      { icon: imgPartner5 },
      { icon: imgPartner6 },
      { icon: imgPartner7 },
      { icon: imgPartner8 },
      { icon: imgPartner9 },
    ],
  };

  const investorsContent = {
    title: `Our <span>Investors</span>`,
    logos: [
      { icon: imgInvestors1 },
      { icon: imgInvestors2 },
      { icon: imgInvestors3 },
      { icon: imgInvestors4 },
      { icon: imgInvestors5 },
      { icon: imgInvestors6 },
      { icon: imgInvestors7 },
      { icon: imgInvestors8 },
      { icon: imgInvestors9 },
    ],
  };

  const items = [
    {
      title: 'Dashboard And Statistics',
      text: 'A full-fledged dashboard allows you to manage the farm conveniently and check up-to-date statistics for each miner and the entire network.',
      icon: img1,
    },
    {
      title: 'Scalable Platform',
      text: 'Add or remove miners in one click. Expand the farm to get more mining power and boost up your revenue.',
      icon: img2,
    },
    {
      title: 'Windows & macOS',
      text: 'Lite and undemanding software, working in the background, turns any desktop or laptop into a mining machine.',
      icon: img3,
    },
    {
      title: 'CryptoTab Staking Algorithm',
      text: 'In the very heart of CryptoTab App is the CryptoTab Browser mining algorithm—fast, stable, proven to be highly profitable.',
      icon: img4,
    },
    {
      title: 'Schedule Devices and Groups',
      text: 'Use a flexible approach to optimize your mining schedule—set busy and inactive hours for individual mining machines or groups.',
      icon: img5,
    },
    {
      title: 'Quick Withdrawals',
      text: 'Reap the profits—withdraw your BTC with no limitations or commissions anytime, even on the first day of mining.',
      icon: img6,
    },
  ]

  return (
    <div className="home">
      <Header />
      <div
        className="container-bg_1"
        style={{ backgroundImage: `url("${bg1}")` }}
      >
        <Future />
        <Features
          items={items}
          title={`Make Any <span>Available Computers</span> Work For You`}
          text="Build up, manage, easily expand a mining farm of thousands of computers."
        />
      </div>
      <div
        className="container-bg_2"
        style={{ backgroundImage: `url("${bg2}")` }}
      >
        {/*<List />*/}
        <Operation />
        <Data />
        <div className="partners-section">
          <Partners content={partnersContent} />
        </div>
        <div className="investors-section">
          <Partners content={investorsContent} />
        </div>
        {/*<TextImage />*/}
      </div>
      <Footer />
    </div>
  )
}
